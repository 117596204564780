import { createApp } from 'vue'
import App from './App.vue'

import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import router from './router'
import firebase from '../firebase.js'
import store from './store';
import './styles/fonts.css';

loadFonts()

const app = createApp(App);

// Globale Funktion definieren
app.config.globalProperties.$filters = {
  money(value) {
    const formatter = Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR'
    })
    return formatter.format(value)
  }
}

app
  .use(vuetify)
  .use(firebase)
  .use(store)
  .use(router)
  .mount('#app')
  
// onAuthStateChanged in die created()-Funktion integrieren
firebase.auth().onAuthStateChanged((user) => {
  if (user) {
    store.dispatch('user/autoSignIn', user);
  }
  if (firebase.auth().currentUser && firebase.auth().currentUser.emailVerified) {
    // store.dispatch('kunden/loadKunden');
  }
});