<template>
  <span>
    <v-btn @click="isActive = true" color="doveGrey" icon><v-icon>settings</v-icon></v-btn>
    <v-dialog width="90%" scrollable v-model="isActive" persistent>
      <v-card :class="mobil ? '' : 'pt-8'" :rounded="mobil ? '' : 'xl'" color="darkBlue"
        style="border: 1px solid #5960F6;">
        <v-card-title class="text-energyRed d-flex align-center justify-space-between">
          Nutzerverwaltung ({{ users.length }} Accounts)
          <v-btn icon variant="outlined" size="small" color="energyRed" @click="isActive = false" class="mr-3">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="d-flex justify-space-between">
          <span>
            <createCP @loadData="fetchUsers()" />
            <v-btn class="ml-2" color="secondary" variant="outlined" @click="fetchUsers()"><v-icon>refresh</v-icon>CPs neu laden</v-btn>
          </span>

          <v-sheet width="300px" color="transparent">
            <v-text-field v-model="search" label="Durchsuchen" density="compact" prepend-inner-icon="search"
              variant="outlined" hide-details single-line></v-text-field>
          </v-sheet>
        </v-card-text>
        <v-card-text class="pa-0">
          <v-data-table-virtual fixed-header :search="search" :loading="loading" :headers="headers"
            no-data-text="Keine CPs vorhanden" loading-text="CPs werden geladen" :items="users" height="400"
            item-value="name">
            <!-- eslint-disable-next-line -->
            <template v-slot:item.fbAuth_id="{ item }">
              <v-icon v-if="item.fbAuth_id">check</v-icon>
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.actions="{ item }">
              <editCP :userDataDefault="item"/>
              <v-btn color="primary" icon variant="text" @click="checkOutCP(item)"><v-icon>login</v-icon></v-btn>
            </template>
          </v-data-table-virtual>
        </v-card-text>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import firebase from 'firebase/compat/app'
import createCP from '../components/dialog/createCP.vue'
import editCP from '../components/dialog/editCP.vue'

export default {
  name: 'userVerwaltung',
  data: () => ({
    isActive: false,
    users: [],
    loading: false,
    search: '',
    headers: [
      { title: 'CP_ID', value: 'cp_id' },
      { title: 'Name', value: 'legalname' },
      { title: 'Email', value: 'email' },
      { title: 'Bestelltyp', value: 'bestelltyp' },
      { title: 'Status', value: 'status' },
      { title: 'Aktionen', value: 'actions', sortable: false }
    ],
  }),
  components: {
    createCP,
    editCP,
  },
  computed: {
    mobil() { return this.$vuetify.display.smAndDown },
  },
  methods: {
    async fetchUsers() {
      this.loading = true
      const users = [];
      try {
        const resHS = await firebase.functions().httpsCallable('getAllCP')()
        const resFB = await firebase.functions().httpsCallable('listUsers')()
        console.log(resFB)
        if (resHS.data.success) {
          resHS.data.res.results.forEach(user => {
            const fbitem = resFB.data.users.find(item => parseInt(item.cp_id) === parseInt(user.id))
            const fbAuth_id = fbitem?.uid
            console.log(fbitem)
            users.push({
              fbitem,
              fbAuth_id, 
              user_id: user.id,
              cp_id: user.properties.channel_partner_id,
              bestelltyp: fbitem?.bestelltyp,
              lagerentfernung: fbitem?.lagerentfernung,
              status: user.properties.status,
              record_id: user.id,
              email: user.properties.e_mail,
              legalname: user.properties.legalname,
            });
          });
          users.sort((a, b) => {
            const numA = parseInt(a.cp_id.match(/\d+/)[0], 10);
            const numB = parseInt(b.cp_id.match(/\d+/)[0], 10);
            return numA - numB;
          });
          this.users = users;
        }
      } catch (error) {
        console.error("Error fetching users: ", error);
      }
      this.loading = false
    },
    async createUser() {
      // Logik zum Erstellen eines Benutzers
    },
    async editUser() {
      // Logik zum Bearbeiten eines Benutzers
    },
    checkOutCP (item) {
      this.$store.dispatch('variables/updateCpDefault', item.record_id);
      this.$store.dispatch('variables/updateCpName', item.legalname);
      this.isActive = false
    }
  },
  created() {
    this.fetchUsers();
  }
}
</script>