import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'

// initial state
const state = {
  bestellungen: [],
  loading: false,
  error: null
}

// getters
const getters = {
  bestellung: (state) => (id) => {
    return state.bestellungen.find(bestellung => bestellung.id === id)
  },
  loading (state) {
    return state.loading
  },
  error (state) {
    return state.error
  }
}

// actions
const actions = {
  // eslint-disable-next-line
  loadBestellungen ({commit}, payload) {
    commit('setLoading', true)
    console.log(payload)
    console.log('Bestellungen laden...')
    firebase.firestore().collection('bestellungen').where('cp_id', '==', payload)
    .onSnapshot(snapshot => {
      console.log(snapshot)
      const bestellungen = []
      snapshot.forEach(doc => {
        bestellungen.push({
          ...doc.data(),
          id: doc.id
        })
        commit('setLoadedBestellungen', bestellungen)
        commit('setLoading', false)
      })
    }, (error) => {
      console.log(error)
      commit('setLoading', false)
    })
  },
  // eslint-disable-next-line
  createBestellung ({commit, getters}, payload) {
    firebase.firestore().collection('bestellungen').add(payload)
      .catch((error) => {
        console.log(error)
      })
  // Reach out to firebase and store it
  },
  deleteBestellungData ({commit}, payload) {
    commit('setLoading', true)
    firebase.firestore().collection('bestellungen').doc(payload).delete()
    .then(() => {
      commit('setLoading', false)
      commit('deleteBestellung', payload)
    })
    .catch(error => {
      console.log(error)
      commit('setLoading', false)
    })
    commit('setLoading', false)
  },
  updateBestellungData ({commit}, payload) {
    commit('setLoading', true)
    const updateObj = {}
    Object.keys(payload).forEach(key => {
      if (payload[key] !== undefined) {
        updateObj[key] = payload[key]
      }
    })
    firebase.firestore().collection('bestellungen').doc(payload.id).update(updateObj)
    .then(() => {
      commit('setLoading', false)
      commit('updateBestellung', payload)
    })
    .catch(error => {
      console.log(error)
      commit('setLoading', false)
    })
  }
}

// mutations
const mutations = {
  setLoadedBestellungen (state, payload) {
    state.bestellungen = payload
  },
  createBestellung (state, payload) {
    state.bestellungen.push(payload)
  },
  updateBestellung (state, payload) {
    const bestellung = state.news.find(bestellung => {
      return bestellung.id === payload.id
    })
    Object.keys(payload).forEach(key => {
      if (payload[key]) {
        bestellung[key] = payload[key]
      }
    })
  },
  deleteBestellung (state, payload) {
    let array = state.bestellungen
    for (var i = 0; i < array.length; i++) {
      if (array[i].id === payload) {
        array.splice(i, 1)
      }
    }
    state.bestellungen = array
  },
  setLoading (state, payload) {
    state.loading = payload
  },
  setError (state, payload) {
    state.error = payload
  },
  clearError (state) {
    state.error = null
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
