<template>
  <span>
    <v-btn @click="isActive = true" color="primary" icon variant="text"><v-icon>edit</v-icon></v-btn>
    <v-dialog width="30%" scrollable v-model="isActive" persistent>
      <v-card :rounded="mobil ? '' : 'xl'" style="border: 1px solid #5960F6;">
        <v-card-title class="text-energyRed d-flex align-center justify-space-between bg-darkBlue">
          CP Daten bearbeiten
          <v-btn icon variant="outlined" size="small" color="energyRed" @click="isActive = false" class="mr-3">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text v-if="!res">
          <v-text-field label="CP-ID*" v-model="userData.cp_id" variant="outlined"
            density="compact"></v-text-field>
          <v-text-field label="Legalname*" v-model="userData.legalname" variant="outlined"
            density="compact"></v-text-field>
            <v-select label="Status*" v-model="userData.status" variant="outlined"
            density="compact" :items="['Sleep', 'Rollout', 'Pilot', 'Lead']"></v-select>
          <v-select label="Bestelltyp*" v-model="userData.bestelltyp" variant="outlined"
            density="compact" :items="['Bulk', 'Ikea']"></v-select>
          <v-select v-if="userData.bestelltyp !== 'Ikea'" label="Entfernung Lager EMH bis Lieferort*" v-model="userData.lagerentfernung" variant="outlined"
            density="compact" :items="[250, 250, 500, 700, 800, 950]"></v-select>
          <v-text-field label="E-Mail_CP*" v-model="userData.e_mail" variant="outlined"
            density="compact"></v-text-field>
          <v-text-field label="Passwort" v-model="userData.password" variant="outlined"
            density="compact" persistent-hint hint="Das Passwort muss nicht geändert werden. Das Passwort muss mindestens 8 Zeichen lang sein und sollte aus Buchstaben, Zahlen und Sonderzeichen bestehen."></v-text-field>
        </v-card-text>
        <v-card-actions class="justify-center" v-if="!res">
          <v-btn variant="outlined" :disabled="!(userData.cp_id && userData.status && userData.legalname && userData.e_mail)"
            @click="editCP()">Updaten</v-btn>
        </v-card-actions>
        <v-card-text v-if="res" class="justify-center">
          <span v-if="res.data?.success">
            <v-icon class="green">check</v-icon>
            Daten gespeichert
          </span>
          <span v-else>
            <v-icon class="secondary">warning</v-icon>
            Fehler beim speichern der Daten. Bitte Screenshot an breuerbe@gmail.com senden.
            <pre>{{ res.data?.res }}</pre>
          </span>
        </v-card-text>

        <v-card-actions v-if="res" class="justify-center">
          <v-btn variant="outlined" @click="isActive = false; res = undefined">Schließen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import firebase from 'firebase/compat/app'

export default {
  name: 'userVerwaltung',
  props: ['userDataDefault'],
  data: () => ({
    isActive: false,
    userData: {},
    loading: false,
    res: undefined
  }),
  mounted() {
    this.userData = {
      legalname: this.userDataDefault.legalname,
      e_mail: this.userDataDefault.email,
      password: '',
      bestelltyp: this.userDataDefault.bestelltyp,
      lagerentfernung: this.userDataDefault.lagerentfernung,
      uid: this.userDataDefault.fbAuth_id,
      hs_object_id: this.userDataDefault.record_id,
      cp_id: this.userDataDefault.cp_id,

      status: this.userDataDefault.status,
    }
  },
  computed: {
    mobil() { return this.$vuetify.display.smAndDown },
  },
  methods: {
    async editCP() {
      const dataFB = {
        displayName: this.userData.legalname,
        email: this.userData.e_mail,
        password: this.userData.password,
        uid: this.userDataDefault.fbAuth_id,
        bestelltyp: this.userData.bestelltyp,
        lagerentfernung: this.userData.lagerentfernung,
      }
      const dataHS = {
        legalname: this.userData.legalname,
        e_mail: this.userData.e_mail,
        channel_partner_id: this.userData.cp_id,
        status: this.userData.status,
      }
      console.log(dataFB)
      const hs_object_id = this.userData.hs_object_id
      const res = await firebase.functions().httpsCallable('changeFBAuthUser')({ dataFB, dataHS, hs_object_id })
      this.res = res
      console.log(res)
      this.userData = {}
    },
    async editUser() {
      // Logik zum Bearbeiten eines Benutzers
    },
  }
}
</script>